import React, { Component } from "react";
import {observer} from "mobx-react";
import {logStore} from "./LogStore";

class CrumbStore {
    state = observer({
        crumb: null,
        loading: false,
        error: null
    });

    get crumb () { return this.state.crumb; }
    get loading () { return this.state.loading; }
    get error () { return this.state.error; }

    async load () {
        logStore.updateLog ("CrumbStore:load ()");
        try {
            this.state.loading = true;
            this.state.crumb = null;
            const crumb = await fetch ("https://bloomberg.etherfirma.com/crumb");
            console.log ("CRUMB", crumb)
            this.state.crumb = crumb;
        } catch (e) {
            logStore.updateLog ("CrumbStore/load: catch " + e);
            this.state.error = e;
        } finally {
            logStore.updateLog ("CrumbStore/load: done => " + this.state.crumb);
            this.state.loading = false;
        }
    }
}

const store = new CrumbStore ();

setTimeout(() => store.load ());

export {
    store as crumbStore
}

// EOF