import React, { Component } from "react";
import {fixedPoint, toCurrency} from "../../util/Utils";
import {observer} from "mobx-react";
import Nope from "./Nope";

const TextView = observer (
    class TextView extends Component {
        render () {
            const { model } = this.props;

            if (! model) {
                return <Nope/>;
            }

            const $ = (val) => <span className={"Dinero"}>{toCurrency (val)}</span>;

            return (
                <div style={{ fontSize: "2em" }}>
                    You grew your investment from {$(model.first.totalPrincipal)} to {$(fixedPoint (model.last.totalPrincipal))} in only {model.config.yearsOfGrowth} years for a gain of {fixedPoint (model.percentageReturn)}%.
                </div>
            );
        }
    }
);

export default TextView;

// EOF