import React, { Component } from "react";
import YahooLogo from "../yahoo.png";
import { wrap } from "../util/Utils";
import "./css/Header.css";
import KeyboardDoubleArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftOutlined";
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import {Chip} from "@mui/material";
import _ from "lodash";
import CachedIcon from "@mui/icons-material/Cached";
import tickerStore from "../store/TickerStore";
import {Link} from "react-router-dom";

const Header = wrap (
    class Header extends Component {
        render() {
            const { tickerStore, logStore, snackbarStore } = this.props;

            return (
                <div className={"Header"}>
                    <div className="Header-Logo" onClick={() => {
                        snackbarStore.show("That tickles!");
                        logStore.updateLog ("You clicked the logo!");
                        return;
                    }}>
                        <img height={48} src={YahooLogo} />
                    </div>
                    <div className={"Header-Workspaces"}>
                        {_.map (tickerStore.workspaces, (el, i) => {
                            return (
                                <Chip
                                    key={i}
                                    label={el.name}
                                    color={tickerStore.active === i ? "primary" : "default"}
                                    onDelete={() => {
                                        logStore.updateLog ("Delete workspace " + i);
                                        tickerStore.deleteWorkspace (i);
                                    }}
                                    onClick={() => {
                                        logStore.updateLog ("Add workspace.");
                                        tickerStore.selectWorkspace (i);
                                    }}
                                />
                            );
                        })}
                        <ControlPointOutlinedIcon onClick={() => {
                            const name = "workspace-" + (tickerStore.workspaces.length + 1);
                            tickerStore.addWorkspace (name);
                        }} />
                    </div>
                    <Link to={"calc"}>Calc</Link>

                    <div className={"Header-SidebarButtons"}>
                        <CachedIcon
                            className={"Header-SidebarButton"}
                            onClick={() => {
                                tickerStore.refresh();
                            }}/>
                        &nbsp;
                        <KeyboardDoubleArrowLeftOutlinedIcon
                            className={"Header-SidebarButton"}
                            onClick={() => {
                                const { sidebarStore } = this.props;
                                sidebarStore.showSidebar ();
                            }}
                        />
                    </div>
                </div>
            );
        }
    }
, [ "sidebarStore" ]);

export default Header;

// EOF