import React, { Component } from "react";
import {observer} from "mobx-react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {toCurrency} from "../../util/Utils";

/**
 Charts documentation lives here... https://recharts.org/en-US/examples *
 * @type {{contextType?: React.Context<any> | undefined, new<P, S>(props: (Readonly<P> | P)): StandardPayments, new<P, S>(props: P, context: any): StandardPayments, prototype: StandardPayments}}
 */

const CURRENCY_FORMATTER = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
});

const StandardPayments = observer (
    class StandardPayments extends Component {
        render() {
            const { core } = this.props;
            let data = core.data;

            return (
                <div>
                    <LineChart
                        width={550}
                        height={400}
                        data={data}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis tickFormatter={(val) => CURRENCY_FORMATTER.format (val)} />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="interest" stroke="#8884d8" dot={false} />
                        <Line type="monotone" dataKey="principal" stroke="#82ca9d" dot={false} />
                    </LineChart>
                </div>
            );
        }
    }
);

export default StandardPayments;

// EOF
