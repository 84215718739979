import {action, observable, toJS} from "mobx";
import { logStore } from "./LogStore";
import Yahoo from "./Yahoo";
import fakeData from "./fakeData";
import _ from "lodash";
import {TICKER_INIT} from "./TickerStoreInit";
import {SummaryWindow} from "../modules/Summary";
import { guid } from "../util/Utils";

const newWorkspace = (name) => {
    return {
        name,
        tickers: [],
        errors: [],
        modules: []
    };
}

/**
 *
 * @type {{data: *[], tickers: string[], errors: string[]}}
 */

class TickerStore {
    store = observable ({
        // workspaces: [ newWorkspace ("Home") ],
        workspaces: TICKER_INIT,
        active: 0,
        data: { }
    });

    getModules (type) {
        return _.filter (this.workspace.modules, (module) => module.type === type);
    }

    hasModule (type) {
        return this.getModules(type).length !== 0;
    }

    addModule (module) {
        module.id = guid ();
        module.show = true;
        this.workspace.modules = [ ...this.workspace.modules, module ];
        return
    }
    removeModules (type) {
        let copy = [ ... toJS (this.workspace.modules) ];
        copy = _.filter (copy, (el) => el.type !== type);
        this.workspace.modules = copy;
        return;
    }

    removeModule (id) {
        logStore.updateLog ("Remove module " + id);
        console.log("removeModule (" + id + ")");
        // let copy = JSON.parse (JSON.stringify  (toJS (this.workspace.modules)));
        // copy = _.filter (copy, (el) => el.id !== id);
        // (action (() => this.workspace.modules = copy)) ();
        // console.log (JSON.stringify (copy, null, 2));

        const copy = JSON.parse (JSON.stringify (toJS (this.workspaces)));
        const workspace = copy[this.active];
        const modules = workspace.modules;
        workspace.modules = _.filter (modules, (el) => {
            // console.log ("CONSIDER", JSON.stringify (el, null, 2));
            const keep = el.id !== id;
            // console.log(keep);
            return keep;
        });

        (action (() => {
            // console.log ("before", JSON.stringify (copy, null, 2));
            this.store.workspaces = copy;
            // console.log ("after", JSON.stringify (this.store.workspaces));
        })) ();
    }

    get workspaces () { return this.store.workspaces; }
    get workspace () { return this.store.workspaces [this.store.active]; }
    get active () { return this.store.active; }
    get modules () { return this.workspace.modules; }
    get data () { return this.store.data; }
    get tickers () { return this.workspace.tickers; }
    get errors () { return this.workspace.errors; }

    addWorkspace (name) {
        console.log ("addWorkspace (" + name + ")");
        this.store.workspaces = [ ...this.workspaces, newWorkspace (name) ];
        this.store.active = this.store.workspaces.length - 1;
        return;
    }

    selectWorkspace (i) {
        console.log ("selectWorkspace (" + i + ")");
        if (i !== this.active) {
            if ( i >= 0 && i < this.workspaces.length) {
                this.store.active = i;
            }
        }
    }

    deleteWorkspace (i) {
        console.log("deleteWorkspace (" + i + ")");
        if (this.workspaces.length > 1) {
            // fIXME:
            const copy = [ ...this.workspaces ];
            copy.splice (i, 1);
            this.store.workspaces = copy;
            if (this.store.active === this.workspaces.length) {
                this.store.active --;
            }
        }
    }

    setTicker (index, ticker) {
        const copy = [ ...this.workspace.tickers ];
        copy [index] = ticker.toUpperCase ();
        this.workspace.tickers = copy;
        if (this.validate (index)) {
            this.doReload (index);
        }
        return;
    }

    validate (which) {
        if (! this.workspace.tickers [which]) {
            this.workspace.errors [which] = "Ticker is required";
            return false;
        } else {
            this.workspace.errors [which] = null;
            return true;
        }
    }

    addTicker () {
        this.workspace.tickers = [ ...this.workspace.tickers, "" ];
        this.workspace.errors = [ ...this.workspace.errors, null ];
        this.validate (this.tickers.length - 1);
        return;
    }

    removeTicker (index) {
        const workspace = this.workspace;

        // TODO: Remove affected modules

        const copy = [ ...workspace.tickers ];
        copy.splice (index, 1);

        const _modules = _.filter (workspace.modules, (el) => el.index !== index);
        console.log ("Filter " + index, _modules);
        _.each (_modules, (el) => {
            if (el.index > index) {
                el.index --;
            }
        });

        (action (() => {
            workspace.tickers = copy;
            workspace.modules = _modules;
        })) ();
        return;
    }

    showSummary (index) {
        logStore.updateLog ("Add a SUMMARY:" + index);
        const module = SummaryWindow.newModule (index);
        tickerStore.addModule (module);
    }

    mapTicker (ticker) {
        return _.findIndex (this.tickers, (el) => el === ticker);
    }

    async doReload (index) {
        const ticker = this.tickers[index];

        if (ticker !== "") {
            const upper = ticker.toUpperCase();
            // FAKE DATA SUPPORT FOR OFFLINE DEVELOPMENT
            // this.data = Object.assign ({ [upper]: fakeData[upper] }, this.data);

            const data = await Yahoo.getQuotes (ticker);
            const qres = data.quoteResponse;
            if (qres.error) {
                logStore.updateLog ("ERROR: " + qres.error);
            } else {
                const copy = Object.assign ({}, toJS (this.data));
                _.each (qres.result, (el, i) => {
                    const previous = copy[el.symbol];
                    if (previous) {
                        el.delta = previous ? (el.regularMarketPrice - previous.regularMarketPrice) : undefined;
                    }
                    logStore.updateLog ("GOT DATA FOR " + el.symbol + " ("+ el.shortName + ")");
                    copy[el.symbol] = el;
                });
                this.store.data = copy;
            }
        }
        return;
    }

    refresh () {
        logStore.updateLog ("Refreshing tickers: " + this.tickers);
        _.each (this.tickers, (ticker, i) => {
            if (ticker) {
                this.doReload (i);
            }
        });
    }
}


// Create a single instance of the tickerStore and start an interval
// thread to do periodic updates of the quotes to simulate real-time
// update behavior.

const tickerStore = new TickerStore ();

setInterval (() => {
    tickerStore.refresh ();
}, 50000);

tickerStore.refresh ();

export default tickerStore;


// EOF