import React, { Component } from "react";
import MortgageConfig from "./MortgageConfig";
import Mortgage from "./Mortgage";
import "./Calculator.css";
import {observer} from "mobx-react";
import IncomeProfile from "./profile/IncomeProfile";
import MortgageProfile from "./profile/MortgageProfile";
import CalculatorViews from "./CalculatorViews";
import RawInputView from "./views/RawInputView";
import {Grid} from "@mui/material";
import TextView from "./views/TextView";
import MortgageHighlights from "./views/MortgageHighlights";
import UpArrow from "./UpArrow";

/**
 *
 */

const CalculatorApp = observer (
    class CalculatorApp extends Component {
        core = new Mortgage ();

        renderOverview () {
            return (
                <div>
                    <h2>How this works</h2>

                    <p>
                        This demonstrates a single mortgage calculator that has a number of views that renders
                        the results of the mortgage in a variety of different ways.
                    </p>

                    <p>
                        Updating the inputs causes all views to dynamically update.
                    </p>

                    <p>
                        The default settings are determined as follows:
                        <ul>
                            <li>
                                If the user has a mortgage profile set, then those values are used.
                            </li>
                            <li>
                                If not, and they have an income profile, then 6x their income is used.
                            </li>
                            <li>
                                Otherwise defaults are placed in the mortgage config that drives the views.
                            </li>
                        </ul>
                    </p>
                </div>
            );
        }

        render() {
            const { core } = this;

            return (
                <div className={"CalculatorApp"}>
                    <div>
                        <h1>Mortgage Calculator</h1>

                        <Grid container spacing={2} padding={1}>
                            <Grid xs={6}>
                                {this.renderOverview()}
                            </Grid>
                            <Grid xs={6}>
                                <h2>Raw Data View</h2>
                                <p>
                                    The raw config for the mortgage calculator core is:
                                </p>
                                <RawInputView store={core.store} />
                            </Grid>
                        </Grid>

                        {core.isValid () && <CalculatorViews core={core} />}
                    </div>
                    <div>
                        <MortgageConfig core={this.core} />
                        <UpArrow />
                        <IncomeProfile />
                        <UpArrow />
                        <MortgageProfile />
                    </div>
                </div>
            );
        }
    }
);

export default CalculatorApp;

// EOF