import React, { Component } from "react";
import "./css/Summary.css";
import WinBox from "react-winbox";
import { wrap } from "../util/Utils";
import {CircularProgress} from "@mui/material";
import classNames from "classnames";
import CachedIcon from "@mui/icons-material/Cached";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import BarChartIcon from "@mui/icons-material/BarChart";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import {DetailsWindow} from "./Details";
import {ChartWindow} from "./Chart";
import {NewsWindow} from "./News";
import {inject} from "mobx-react";

const Summary = wrap (
    class Summary extends Component {

        renderButtons (ticker, i) {
            const { tickerStore } = this.props;

            return (
                <div className={"SummaryButtons"}>
                    <CachedIcon onClick={() => tickerStore.doReload (i)}/>
                    &nbsp;
                    <ArticleOutlinedIcon onClick={() => {
                        tickerStore.addModule (DetailsWindow.newModule (i));
                        // tickerStore.showDetails (i)
                    }} />
                    &nbsp;
                    <BarChartIcon onClick={() => {
                        tickerStore.addModule (ChartWindow.newModule (i));
                        // tickerStore.showChart (i)
                    }} />
                    &nbsp;
                    <DeleteOutlineOutlinedIcon onClick={() => {
                        tickerStore.removeTicker (i)
                    }} />
                    &nbsp;
                    <NewspaperIcon onClick={() => {
                        tickerStore.addModule (NewsWindow.newModule (i));
                    }}/>
                </div>
            );
        }

        render () {
            const { data, ticker } = this.props;
            const { tickerStore } = this.props;

            if (! data) {
                return (
                    <div style={{ margin: 20 }}>
                        <CircularProgress />
                    </div>
                );
            }

            const i = tickerStore.mapTicker (ticker);

            const getColor = (data) => {
                if (data.delta) {
                    if (data.delta > 0) {
                        return "Summary-Change-Positive";
                    } else {
                        return "Summary-Change-Negative";
                    }
                }
                return "Summary-Change-Neutral";
            }

            const color = getColor (data);
            const colored = classNames ({
                "Summary-Numbers": true,
                [color]: true
            });

            return (
                <div className={"SummaryWrapper"}>
                    <div className={"Summary"}>
                        <div className={"Summary-Text"}>
                            <div className={"Summary-Symbol"}>
                                {ticker}
                            </div>
                            <div className={"Summary-Name"}>
                                {data.longName}
                            </div>
                        </div>
                        <div className={colored}>
                            <div>
                                <span className={"Summary-Price"}>
                                    {data.regularMarketPrice}
                                </span>
                                    <span className={"Summary-Currency"}>
                                    {data.currency}
                                </span>
                            </div>
                            <div className={"Summary-Change"}>
                                {data.delta}
                            </div>
                        </div>
                    </div>
                    {this.renderButtons (ticker, i)}
                </div>
            );
        }
    }
);

const SummaryWindow = wrap (
    class SummaryWindow extends Component {
        static TYPE = "summary";

        static newModule (index) {
            return {
                type: SummaryWindow.TYPE,
                size: { w: 350, h: 190 },
                coords: { x: 300, y: 200 },
                props: { },
                index
            };
        }

        render () {
            const { conf, data, ticker, controller } = this.props;
            const { coords, size } = conf;

            return (
                <WinBox
                    className={"Summary-Window"}
                    title={"Summary: " + ticker}
                    width={size.w}
                    height={size.h}
                    x={coords.x}
                    y={coords.y}
                    onClose={controller.close}
                    onMove={controller.setCoords}
                    onResize={controller.setSize}
                >
                    <Summary data={data} ticker={ticker} />
                </WinBox>
            );
        }
    }
);

export {
    Summary,
    SummaryWindow
};

// EOF