import React, { Component } from "react";
import Nope from "./Nope";

class DumpModel extends Component {
    render() {
        const { model } = this.props;

        if (! model) {
            return <Nope/>;
        }

        return (
            <pre>
                {JSON.stringify (model, null, 2)}
            </pre>
        );
    }
}

export default DumpModel;

// EOF