import React, { Component } from "react";
import {observer} from "mobx-react";

const RawInputView = observer (
    class RawInputView extends Component {
        render() {
            const { store } = this.props;
            return (
                <div>
                    <pre className={"AdornedPre"}>
                        {JSON.stringify (store, null, 2)}
                    </pre>
                </div>
            );
        }
    }
);

export default RawInputView;

// EOF