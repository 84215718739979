import React, { Component } from "react";
import {observer} from "mobx-react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {generateSequence, toCurrency} from "../../util/Utils";
import _ from "lodash";
import "./TabularPayments.css";

/**
 *
 * @type {{contextType?: React.Context<any> | undefined, new<P, S>(props: (Readonly<P> | P)): TabularPayments, new<P, S>(props: P, context: any): TabularPayments, prototype: TabularPayments}}
 */

const TabularPayments = observer (
    class TabularPayments extends Component {
        render() {
            const { core } = this.props;
            let { data } = core;

            const headers = [
                "Month",
                "Interest",
                "Principal",
                "Interest Total",
                "Principal Total",
                "Principal Remaining"
            ];

            return (
                <TableContainer className="TabularView" component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" >
                        <TableHead>
                            <TableRow>
                                {_.map (headers, (header, i) => {
                                    return (
                                        <TableCell key={i} align={"right"}>
                                            {header}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.map (data, (el, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell>
                                            {el.month}.
                                        </TableCell>
                                        <TableCell align="right">
                                            {toCurrency(el.interest)}
                                        </TableCell>
                                        <TableCell align="right">
                                            {toCurrency (el.principal)}
                                        </TableCell>
                                        <TableCell align="right">
                                            {toCurrency (el.interestPaid)}
                                        </TableCell>
                                        <TableCell align="right">
                                            {toCurrency (el.principalPaid)}
                                        </TableCell>
                                        <TableCell align="right">
                                            {toCurrency (el.principalRemaining)}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        }
    }
);

export default TabularPayments;

// EOF