import React, { Component } from "react";
import WinBox from "react-winbox";
import {action} from "mobx";
import { wrap } from "../util/Utils";
import "./css/News.css";
import Lipsum from "../util/Lipsum";
import _ from "lodash";
import {inject} from "mobx-react";

/**
 * Utility class for generating fake articles.
 */
class NewsUtil {
    getArticles (n) {
        const headlines = Lipsum.short (n);
        const synopsis = Lipsum.medium (n);
        const images = Lipsum.images (n);
        const urls = Lipsum.urls (n);
        const publishers = Lipsum.publishers (n);

        const articles = [];
        for (let i = 0; i < n; i ++) {
            const article = {
                headline: headlines[i],
                synopsis: synopsis[i],
                imageUrl: images [i],
                articleUrl: urls [i],
                publisher: publishers [i]
            };
            articles.push (article);
        }
        return articles;
    }
}

const newsUtil = new NewsUtil ();

class NewsArticle extends Component {
    render() {
        const { article } = this.props;

        return (
            <div className={"News-Article"} onClick={() => {
                window.open (article.articleUrl, "_blank");
            }}>
                <div className={"News-Image"}>
                    <img alt={"none"} src={article.imageUrl} />
                </div>
                <div className={"News-Text"}>
                    <div className={"News-Headline"}>
                        {article.headline}
                    </div>
                    <div className={"News-Publisher"}>
                        {article.publisher}
                    </div>
                    <p>{article.synopsis}</p>
                </div>
            </div>
        );
    }
}

/**
 * The react component that renders the article list.
 *
 * @type {(* & IWrappedComponent<never>)|(* & IWrappedComponent<never>)}
 */

const NewsPanel = wrap (
    class NewsPanel extends Component {
        render() {
            const { ticker } = this.props;

            const articles = newsUtil.getArticles(3);

            return (
                <div className={"News"}>
                    {_.map (articles, (article, i) => {
                        return <NewsArticle key={i} article={article} />;
                    })}
                </div>
            );
        }
    }
);

/**
 * Window for wrapping the articles component.
 */
const NewsWindow = wrap (
    class NewsWindow extends Component {
        static TYPE = "news";

        static newModule(index) {
            return {
                type: NewsWindow.TYPE,
                size: {w: 800, h: 325},
                coords: {x: 300, y: 300},
                props: {},
                index
            };
        }

        render () {
            const { conf, data, ticker, controller } = this.props;
            const { coords, size } = conf;

            return (
                <WinBox
                    className={"News-Window"}
                    title={ticker + " Headlines"}
                    width={size.w}
                    height={size.h}
                    x={coords.x}
                    y={coords.y}
                    onClose={controller.close}
                    onMove={controller.setCoords}
                    onResize={controller.setSize}
                >
                    <NewsPanel ticker={ticker} />
                </WinBox>
            );
        }
    }
);

export {
    NewsPanel,
    NewsWindow
};

// EOF