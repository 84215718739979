import React, { Component } from "react";

class Nope extends Component {
    render() {
        return (
            <div>
                Invalid configuration.
            </div>
        );
    }
}

export default Nope;

// EOF