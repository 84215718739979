import {observable, toJS} from "mobx";
import {fixedPoint, isFloat, isInt} from "../util/Utils";

/**
 * A class to calculator the numerical values associated with a mortgage.
 */
class Mortgage {
    store = observable({
        rate: null,
        amount: null,
        months: null,
        errors: { }
    });

    validate () {
        const { store } = this;
        const { rate, amount, months } = store;
        const errors = {};

        if (rate === "") {
            errors.rate = "Rate is required";
        } else if (! isFloat (rate)) {
            errors.rate = "Invalid interest rate";
        }
        if (amount === "") {
            errors.amount = "Amount is required";
        } else if (! isInt (amount)) {
            errors.amount = "Invalid amount";
        }
        if (months === "") {
            errors.months = "Months is required";
        } else if (! isInt (months)) {
            errors.months = "Invalid months.";
        }

        this.store.errors = errors;
    }

    isValid () {
        return Object.keys(this.store.errors).length === 0;
    }

    set rate (newRate) {
        this.store.rate = newRate;
        this.validate ();
    }

    get rate () {
        return this.store.rate;
    }

    get errors () {
        return this.store.errors;
    }

    set amount (newAmount) {
        this.store.amount = newAmount;
        this.validate ();
    }

    get amount () {
        return this.store.amount;
    }

    set months (newMonths) {
        this.store.months = newMonths;
        this.validate ();
    }

    get months () {
        return this.store.months;
    }

    get payment () {
        if (! this.isValid ()) {
            return null;
        }
        const { rate, months: N, amount: P } = toJS (this.store);
        const r = rate / 100 / 12;
        const a =  (r * P);
        const b = 1 - ((1 + r) ** -N);
        return fixedPoint (a / b);
    }

    get totalPayments () {
        if (! this.isValid ()) {
            return null;
        }
        const { months: N } = this.store;
        return this.payment * N;
    }

    get interestPaid () {
        if (! this.isValid ()) {
            return null;
        }
        return this.totalPayments - this.amount;
    }

    get data () {
        if (! this.isValid ()) {
            return null;
        }
        const els = [];
        const { payment, amount, rate } = this;
        let totalPaid = 0;
        let interestPaid = 0;
        let principalPaid = 0;
        for (let i = 0; i < this.months; i ++) {
            const interest = rate / 100 / 12 * (amount - principalPaid);
            const principal = payment - interest;
            totalPaid += payment;
            interestPaid += interest;
            principalPaid += principal;
            const el = {
                month: i + 1,
                payment,
                interest,
                principal,
                totalPaid,
                interestPaid,
                principalPaid,
                principalRemaining: amount - principalPaid
            };
            els.push (el);
        }
        return els;
    }
}

export default Mortgage;

// EOF