import React, { Component } from "react";
import {observer} from "mobx-react";
import MortgageHighlights from "./views/MortgageHighlights";
import {toCurrency} from "../util/Utils";
import PieChart1 from "./views/PieChart1";
import Components from "./views/Components";
import StandardPayments from "./views/StandardPayments";
import TabularPayments from "./views/TabularPayments";
import EquityView from "./views/EquityView";
import RawOutputView from "./views/RawOutputView";
import TextView from "./views/TextView";
import {Grid} from "@mui/material";

function StandardChart(props) {
    return null;
}

StandardChart.propTypes = {};
const CalculatorViews = observer (
    class CalculatorViews extends Component {
        render() {
            const { core } = this.props;

            return (
                <div>

                    <Grid container spacing={2} padding={1}>
                        <Grid xs={6}>
                            <h2>Simple Text View</h2>
                            <TextView core={core} />
                        </Grid>
                        <Grid xs={6}>
                            <h2>Highlights View</h2>
                            <MortgageHighlights core={core} />
                        </Grid>
                        <Grid xs={6}>
                            <h2>Standard View</h2>
                            <StandardPayments core={core} />
                        </Grid>
                        <Grid xs={6}>
                            <h2>Equity View</h2>
                            <EquityView core={core} />
                        </Grid>
                        <Grid xs={6}>
                            <h2>Components of Payment View</h2>
                            <Components core={core} />
                        </Grid>
                        <Grid xs={6}>
                            <h2>Pie Chart View</h2>
                            <PieChart1 core={core} />
                        </Grid>
                        <Grid xs={12}>
                            <h2>Tabular Data View</h2>
                            <TabularPayments core={core} />
                        </Grid>
                        <Grid xs={6}>
                            <h2>Raw Output View</h2>
                            <RawOutputView core={core} />
                        </Grid>
                    </Grid>
                </div>
            );
        }
    }
);

export default CalculatorViews;

// EOF