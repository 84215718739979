import React, { Component } from "react";
import WinBox from "react-winbox";
import {logStore, LogStorePanel} from "./LogStore";
import {TabPanel, wrap} from "../util/Utils";
import {Tab, Tabs} from "@mui/material";
import {observable} from "mobx";
import _ from "lodash";
import "./css/DataBrowser.css";

const DataBrowser = wrap (
    class DataBrowser extends Component {
        store = observable ({
            tab: 0
        });

        render() {
            const { tickerStore } = this.props;
            const { workspaces } = tickerStore;
            const { tab } = this.store;

            return (
                <div style={{ margin: 8 }}>
                    <Tabs value={tab} onChange={(e, i) => this.store.tab = i}>
                        {_.map (workspaces, (el, i) => {
                            return(
                                <Tab label={el.name} key={i} />
                            )
                        })};
                        <Tab label={"Data"} />
                    </Tabs>
                    {_.map (workspaces, (el, i) => {
                        return (
                            <TabPanel value={tab} index={i}>
                                <pre>
                                    {JSON.stringify (el, null, 2)}
                                </pre>
                            </TabPanel>
                        );
                    })}
                    <TabPanel value={tab} index={workspaces.length}>
                        <pre>{JSON.stringify (tickerStore.data, null, 2)}</pre>
                    </TabPanel>
                </div>
            );
        }
    }
);

const DataBrowserWindow = wrap (
    class DataBrowserWindow extends Component {
        static TYPE = "$dataBrowser";

        static newModule () {
            return {
                type: DataBrowserWindow.TYPE,
                size: { w: 600, h: 400 },
                coords: { x: 300, y: 500 }
            };
        }

        render() {
            const { conf, tickerStore } = this.props;
            const { coords, size } = conf;

            return (
                <WinBox
                    className={"DataBrowser-Window"}
                    width={size.w}
                    height={size.h}
                    x={coords.x}
                    y={coords.y}
                    title={"Data Browser"}
                    // noClose={this.state.inEditing}
                    onMove={(x, y) => {
                        conf.coords = { x, y };
                    }}
                    onResize={(w, h) => {
                        conf.size = { w, h };
                    }}
                >
                    <DataBrowser />
                </WinBox>
            );
        }
    }
);

export {
    DataBrowser,
    DataBrowserWindow
};

// EOF