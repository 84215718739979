import {observable} from "mobx";
import {fixedPoint, isFloat, isInt} from "../util/Utils";
import _ from "lodash";

const asEnum = (arr) => {
    const obj = {};
    _.each (arr, (el, _) => obj [el] = el);
    return obj;
};


const DEFAULT = {
    mode: "GENERIC",
    initialDeposit: "1000.0",
    contributionAmount: "100.0",
    contributionFrequency: "ANNUALLY",
    yearsOfGrowth: "5",
    rateOfReturn: "3.25",
    compoundFrequency: "MONTHLY",
    showContribution: true,
    showCompoundFrequency: true,
    model: null
};

class CompoundInterestCalculator {
    constructor() {
        this.validate ();
    }

    store = observable({
        initialDeposit: DEFAULT.initialDeposit,
        contributionAmount: DEFAULT.contributionAmount,
        contributionFrequency: DEFAULT.contributionFrequency,
        yearsOfGrowth: DEFAULT.yearsOfGrowth,
        rateOfReturn: DEFAULT.rateOfReturn,
        compoundFrequency: DEFAULT.compoundFrequency,
        showContribution: DEFAULT.showContribution,
        showCompoundFrequency: DEFAULT.showCompoundFrequency,
        mode: DEFAULT.mode,
        errors: {}
    });

    validate() {
        const {store} = this;
        const {
            initialDeposit,
            contributionAmount,
            contributionFrequency,
            yearsOfGrowth,
            rateOfReturn,
            compoundFrequency
        } = store;
        const errors = {};


        if (contributionFrequency === "") {
            errors.contributionFrequency = "Value is required";
        } else if (! _.indexOf (CompoundInterestCalculator.CONTRIBUTION_FREQ, contributionFrequency) === -1) {
            errors.contributionFrequency = "Invalid contribution frequency.";
        }
        if (compoundFrequency === "") {
            errors.compoundFrequency = "Value is required";
        } else if (! _.indexOf (CompoundInterestCalculator.COMPOUND_FREQ, compoundFrequency) === -1) {
            errors.compoundFrequency = "Invalid compound frequency.";
        }

        if (initialDeposit === "") {
            errors.initialDeposit = "Value is required";
        } else if (!isFloat (initialDeposit)) {
            errors.initialDeposit = "Invalid amount.";
        }
        if (yearsOfGrowth === "") {
            errors.yearsOfGrowth = "Value is required";
        } else if (!isInt (yearsOfGrowth) || yearsOfGrowth < 1) {
            errors.yearsOfGrowth = "Invalid amount.";
        }
        if (contributionAmount === "") {
            errors.contributionAmount = "Value is required";
        } else if (!isFloat (contributionAmount)) {
            errors.contributionAmount = "Invalid amount.";
        }
        if (rateOfReturn === "") {
            errors.rateOfReturn = "Value is required";
        } else if (!isFloat (rateOfReturn)) {
            errors.rateOfReturn = "Invalid amount.";
        }

        this.store.errors = errors;
        if (this.isValid) {
            this.recompute ();
        }
        return;
    }

    get isValid() {
        return Object.keys (this.store.errors).length === 0;
    }

    get errors () {
        return this.store.errors;
    }

    get initialDeposit () {
        return this.store.initialDeposit;
    }
    get contributionAmount () {
        return this.store.contributionAmount;
    }
    get contributionFrequency () {
        return this.store.contributionFrequency;
    }
    get yearsOfGrowth () {
        return this.store.yearsOfGrowth;
    }
    get rateOfReturn () {
        return this.store.rateOfReturn;
    }
    get compoundFrequency () {
        return this.store.compoundFrequency;
    }

    get showCompoundFrequency () {
        return this.store.showCompoundFrequency;
    }

    get showContribution () {
        return this.store.showContribution;
    }

    get mode () {
        return this.store.mode;
    }

    ///

    get yearsOfGrowth_i () {
        return parseInt (this.store.yearsOfGrowth);
    }
    get rateOfReturn_f () {
        return parseFloat (this.store.rateOfReturn);
    }
    get initialDeposit_f () {
        return parseFloat (this.store.initialDeposit);
    }
    get contributionAmount_f () {
        return parseFloat (this.store.contributionAmount);
    }

    set initialDeposit (value) {
        this.store.initialDeposit = value;
        this.validate ();
    }
    set contributionAmount (value) {
        this.store.contributionAmount = value;
        this.validate ();
    }
    set contributionFrequency (value) {
        this.store.contributionFrequency = value;
        this.validate ();
    }
    set yearsOfGrowth (value) {
        this.store.yearsOfGrowth = value;
        this.validate ();
    }
    set rateOfReturn (value) {
        this.store.rateOfReturn = value;
        this.validate ();
    }
    set compoundFrequency (value) {
        this.store.compoundFrequency = value;
        this.validate ();
    }

    set showCompoundFrequency (value) {
        this.store.showCompoundFrequency = value;
    }

    set showContribution (value) {
        this.store.showContribution = value;
    }

    set mode (value) {
        if (value === "GENERIC") {
            this.showCompoundFrequency = true;
            this.showContribution = true;
        } else if (value === "SAVINGS") {
            this.showCompoundFrequency = false;
            this.compoundFrequency = "MONTHLY";
            this.showContribution = true;
        } else if (value === "CD") {
            this.showCompoundFrequency = false;
            this.compoundFrequency = "MONTHLY";
            this.showContribution = false;
            this.contributionAmount = 0.0;
        }
        this.store.mode = value;
    }

    recompute () {
        this.model = null;
        if (! this.isValid) {
            return;
        }

        // Get our inputs

        const { store } = this;
        let {
            initialDeposit: initialDeposit,
            contributionAmount: contributionAmount,
            contributionFrequency,
            yearsOfGrowth: yearsOfGrowth,
            rateOfReturn: rateOfReturn,
            compoundFrequency
        } = store;

        initialDeposit = parseFloat (initialDeposit);
        contributionAmount = parseFloat (contributionAmount);
        rateOfReturn = parseFloat (rateOfReturn);
        yearsOfGrowth = parseInt (yearsOfGrowth);

        // Setup the results data structure

        const model = {
            config: {
                initialDeposit,
                contributionAmount,
                contributionFrequency,
                yearsOfGrowth,
                rateOfReturn,
                compoundFrequency
            },
            period: []
        };

        model.period.push ({
            principal: initialDeposit,
            totalPrincipal: initialDeposit,
            contribution: 0,
            totalContribution: 0,
            interest: 0,
            totalInterest: 0
        })

        // Calculate the periods

        let initial = model.period[0];
        let last = initial;

        for (let year = 0; year < yearsOfGrowth; year ++) {
            let principal = 0;
            let interest = 0;
            let contribution = 0;

            for (let month = 0; month < 12; month ++) {
                if (contributionFrequency === "MONTHLY") {
                    // principal += contributionAmount;
                    contribution = contributionAmount;
                } else if (contributionFrequency === "ANNUALLY") {
                    if (month === 11) {
                        // principal += contributionAmount;
                        contribution = contributionAmount;
                    }
                }
                if (compoundFrequency === "MONTHLY") {
                    interest += fixedPoint ((last.totalPrincipal + principal + last.totalInterest + interest + contribution) * rateOfReturn / 12 / 100);
                } else if (compoundFrequency === "ANNUALLY") {
                    if (month === 11) {
                        interest += fixedPoint ((last.totalPrincipal + principal + last.totalInterest + contribution) * rateOfReturn / 100);
                    }
                }
            }
            const rec = {
                principal,
                totalPrincipal: last.totalPrincipal + principal + interest + contribution,
                interest,
                totalInterest: last.totalInterest + interest,
                contribution,
                totalContribution: last.totalContribution + contribution
            };
            model.period.push (rec);
            last = rec;
        }

        // Calculate some convenience values

        model.totalReturn = last.totalPrincipal - initial.totalPrincipal;
        model.percentageReturn = model.totalReturn / initial.totalPrincipal * 100;
        model.first = initial;
        model.last = last;
        this.model = model;
        return;
    }
}

CompoundInterestCalculator.prototype.MODE = [ "GENERIC", "CD", "SAVINGS" ];
CompoundInterestCalculator.prototype.CONTRIBUTION_FREQ = [ "MONTHLY", "ANNUALLY" ];
CompoundInterestCalculator.prototype.COMPOUND_FREQ = [ /* "DAILY", */ "MONTHLY", "ANNUALLY" ];

export default CompoundInterestCalculator;

// EOF