import React, { Component } from "react";
import Paper from "@mui/material/Paper";
import {Button} from "@mui/material";
import {observable} from "mobx";
import WinBox from "react-winbox";
import _ from "lodash";
import { wrap } from "../util/Utils";
import "./css/LogStore.css";

const logStore = observable ({
    log: [ "Log initialized." ],

    clearLog: () => {
        logStore.log = [
            "Log cleared at " + new Date ()
        ];
    },

    updateLog: (message) => {
        logStore.log = [...logStore.log, message ];
    }
});

const LogStorePanel = wrap (
    class LogStorePanel extends Component {
        render () {
            return (
                <div style={{ margin: 8 }}>
                    <Button variant={"outlined"} onClick={() => logStore.clearLog ()}>
                        Clear
                    </Button>
                    <pre>
                        {_.map (logStore.log, (el, i) => {
                          return (
                              <div key={i}>
                                {el}
                             </div>
                          )
                        })}
                    </pre>
                </div>
            );
        }
    }
);

const LogStoreWindow = wrap (
    class LogStoreWindow extends Component {
        static TYPE = "$logStore";

        static newModule () {
            return {
                type: LogStoreWindow.TYPE,
                size: { w: 800, h: 400 },
                coords: { x: 200, y: 750 }
            };
        }

        render () {
            const { conf, tickerStore } = this.props;
            const { coords, size } = conf;
            console.log ("LogStore", conf);

            return (
                <WinBox
                    className={"Log-Window"}
                    // width={this.state.boxWidth ?? 500}
                    width={size.w}
                    height={size.h}
                    x={coords.x}
                    y={coords.y}
                    title={"Debug Log"}
                    // noClose={this.state.inEditing}
                    onMove={(x, y) => {
                        conf.coords = { x, y };
                    }}
                    onResize={(w, h) => {
                        conf.size = { w, h };
                    }}
                >
                    <LogStorePanel />
                </WinBox>
            );
        }
    }
);

export {
    logStore,
    LogStorePanel,
    LogStoreWindow
}

// EOF