import React, { Component } from "react";
import {fixedPoint, toCurrency} from "../../util/Utils";
import {observer} from "mobx-react";
import {HighlightBox} from "../../calc/views/MortgageHighlights";
import Nope from "./Nope";

const Highlights = observer (
    class Highlights extends Component {
        render() {
            const { model } = this.props;

            if (! model) {
                return <Nope/>;
            }

            return (
                <div className={"MortgageHighlights"}>
                    <HighlightBox label={"Principal"} quantity={toCurrency (model.first.principal)} />
                    <HighlightBox label={"Years"} quantity={model.config.yearsOfGrowth} />
                    <HighlightBox label={"Total Interest"} quantity={toCurrency (model.last.totalInterest)} />
                    <HighlightBox label={"Total Return"} quantity={fixedPoint (model.percentageReturn)} />
                </div>
            );
        }
    }
);

export default Highlights;

// EOF