import React, { Component } from "react";
import classnames from "classnames";
import "./css/Valid.css";

class Valid extends Component {
    render() {
        const { value = false } = this.props;

        return (
            <span className={classnames("Valid", `Valid-${value}`)}>
                ● {value ? "VALID": "INVALID"}
            </span>
        );
    }
}

export default Valid;

// EOF