import React, { Component } from 'react'

import _ from "lodash";
import 'winbox/dist/css/winbox.min.css'; // required
import 'winbox/dist/css/themes/modern.min.css'; // optional
import WinBox from 'react-winbox';
import "./css/TickerPicker.css";

import YahooLogo from "../yahoo.png";
import {Button, TableFooter, TextField} from "@mui/material";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import CachedIcon from '@mui/icons-material/Cached';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';


import SubjectIcon from '@mui/icons-material/Subject';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import BugReportIcon from '@mui/icons-material/BugReport';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import { wrap } from "../util/Utils";
import {observable} from "mobx";
import {ActiveTickerWindow} from "./ActiveTickers";
import {LogStorePanel, LogStoreWindow} from "../store/LogStore";
import {DataBrowserWindow} from "../store/DataBrowser";
import TestWindow from "../store/TestStore";

const TickerPicker = wrap (
    class TickerPicker extends Component {
        store = observable ({
            uindex: null,
            uvalue: ""
        });

        /**
         *
         * @returns {JSX.Element}
         */
        renderSettings () {
            const { tickerStore } = this.props;
            const toStyle = (type) => {
                return {
                    color: tickerStore.hasModule(type) ? "black" : "lightgray"
                }
            };

            return (
                <div className={"TickerPicker-Settings"}>
                    <SubjectIcon style={toStyle (LogStoreWindow.TYPE)} onClick={() => {
                        const type = LogStoreWindow.TYPE;
                        if (! tickerStore.hasModule (type)) {
                            console.log ("Add module " + type);
                            tickerStore.addModule (LogStoreWindow.newModule ());
                        } else {
                            tickerStore.removeModules(type);
                        }
                    }} />
                   &nbsp;
                   <FormatListBulletedIcon style={toStyle (ActiveTickerWindow.TYPE)} onClick={() => {
                       const type = ActiveTickerWindow.TYPE;
                       if (! tickerStore.hasModule (type)) {
                           console.log ("Add module " + type);
                           tickerStore.addModule (ActiveTickerWindow.newModule ());
                       }
                       else {
                           tickerStore.removeModules(type);
                       }
                   }} />
                    &nbsp;
                    <BugReportIcon style={toStyle (DataBrowserWindow.TYPE)} onClick={() => {
                        const type = DataBrowserWindow.TYPE;
                        if (! tickerStore.hasModule (type)) {
                            console.log ("Add module " + type);
                            tickerStore.addModule (DataBrowserWindow.newModule ());
                        } else {
                            tickerStore.removeModules(type);
                        }
                    }} />
                    &nbsp;
                    <AirplanemodeActiveIcon style={toStyle (TestWindow.TYPE)} onClick={() => {
                        const type = TestWindow.TYPE;
                        if (! tickerStore.hasModule (type)) {
                            console.log ("Add module " + type);
                            tickerStore.addModule (TestWindow.newModule ());
                        } else {
                            tickerStore.removeModules(type);
                        }
                    }} />
                    &nbsp;
                    <CopyToClipboard text={JSON.stringify (tickerStore.workspaces)}>
                        <ContentCopyIcon className={"TickerPicker-Copy"} />
                    </CopyToClipboard>
                </div>
            );
        }

        renderTickers () {
            const { tickerStore } = this.props;
            const { workspace } = tickerStore;
            const { tickers, errors } = workspace;

            return (
                <TableContainer className="TickerPicker-Tickers" component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell size={"small"}>Ticker</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.map (tickers, (el, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell size={"small"}>
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                size={"small"}
                                                onFocus={(e) => {
                                                    this.store.uindex = i;
                                                    this.store.uvalue = tickers [i] || "";
                                                    this.props.logStore.updateLog ("FOCUS " + i)
                                                }}
                                                onBlur={(e) => {
                                                    this.store.uindex = null;
                                                }}
                                                onChange={(e) => {
                                                    this.store.uvalue = e.target.value || null;
                                                }}
                                                onKeyUp={(e) => {
                                                    if (e.key === 'Enter') {
                                                        tickerStore.setTicker (i, this.store.uvalue);
                                                        this.props.logStore.updateLog ("SET [" + i + "] to " + this.store.uvalue);
                                                    }
                                                }}
                                                value={this.store.uindex === i ? this.store.uvalue : (tickers[i] || "")}
                                                helperText={errors[i]}
                                                error={Boolean(errors[i])}
                                                sx={{ width: 150 }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <CachedIcon onClick={() => tickerStore.doReload (i)}/>
                                            &nbsp;
                                            <MonetizationOnOutlinedIcon onClick={() => tickerStore.showSummary (i)} />
                                            &nbsp;
                                            <DeleteOutlineOutlinedIcon onClick={() => tickerStore.removeTicker (i)}/>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <Button variant={"outlined"} size={"small"} onClick={() => tickerStore.addTicker ()}>
                                        <AddOutlinedIcon />
                                        &nbsp;
                                        Add Ticker
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            );
        }

        /**
         *
         * @returns {JSX.Element}
         */
        render() {
            const { tickerStore } = this.props;

            return (
                <div className={"TickerPicker"}>
                    {this.renderTickers ()}
                    {this.renderSettings ()}
                </div>
            );
        }
    }
);

class TickerPickerWindow extends Component {
    render() {
        return (
            <WinBox
                // width={this.state.boxWidth ?? 500}
                width={450}
                height={500}
                x="center"
                title={"Yahoo Finance"}
                y={30}
                // noClose={this.state.inEditing}
            >
                <TickerPicker />
            </WinBox>
        );
    }
}

export {
    TickerPicker,
    TickerPickerWindow
};

// EOF