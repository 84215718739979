import React, { Component } from "react";
import {toCurrency} from "../../util/Utils";
import {observer} from "mobx-react";

const TextView = observer (
    class TextView extends Component {
        render () {
            const { core } = this.props;
            const $ = (val) => <span className={"Dinero"}>{toCurrency (val)}</span>;

            return (
                <div style={{ fontSize: "2em" }}>
                    Your total mortgage payment will be: {$(core.payment)} for a total payments over {core.months} months of {$(core.totalPayments)} for a total amount of interest paid
                    of {$(core.interestPaid)}.
                </div>
            );
        }
    }
);

export default TextView;

// EOF