import React, { Component } from "react";
import {BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label} from 'recharts';
import {toCurrency} from "../../util/Utils";
import Nope from "./Nope";

class HistogramView extends Component {
    render() {
        const { model, showContributions } = this.props;

        if (! model) {
            return <Nope/>;
        }

        return (
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={400}
                    height={250}
                    data={model.period}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis tickFormatter={(e) => "$" + toCurrency(e)}>
                    </YAxis>
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="totalPrincipal" name="Principal" stackId="a" fill="#8884d8" />
                    <Bar dataKey="contribution" name="Contribution" stackId="a" fill="darkblue" />
                    <Bar dataKey="totalInterest" name="Interest" stackId="a" fill="#82ca9d" />
                </BarChart>
            </ResponsiveContainer>
        );
    }
}

export default HistogramView;

// EOF