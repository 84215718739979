import React, { Component } from "react";
import "./css/CompoundInterest.css";
import {observer} from "mobx-react";
import {observable} from "mobx";
import CompoundInterestCalculator from "./CompoundInterestCalculator";
import Valid from "./Valid";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import _ from "lodash";
import {fixedPoint, toCurrency} from "../util/Utils";
import TextView from "./views/TextView";
import Highlights from "./views/Highlights";
import HistogramView from "./views/HistogramView";
import DumpModel from "./views/DumpModel";

class CIRow extends Component {
    render() {
        return (
            <div className={"CompoundInterestRow"}>
                {this.props.children}
            </div>
        );
    }
}

class Money extends Component {
    render () {
        const { value = 0.0 } = this.props;
        return (
            <span style={{fontSize: "2em", "display": "inline-block" }}>
                {toCurrency (value)}
            </span>
        );
    }
}

class CIBlock extends Component {
    render() {
        const { header = "Header Missing", ...extra } = this.props;

        return (
            <div className={"CompoundInterestCol"} {...extra}>
                <h2>{header}</h2>
                {this.props.children}
            </div>
        );
    }
}

const CompoundInterestApp = observer (
    class CompoundInterestApp extends Component {
        store = observable ({
            calc: new CompoundInterestCalculator (),
        });

        select = (which, label, values) => {
            const {calc} = this.store;

            return (
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        {label}
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={calc[which]}
                        label={label}
                        size={"small"}
                        onChange={(e) => {
                            calc[which] = e.target.value;
                        }}
                    >
                        {_.map (values, (el, i) => {
                            return (
                                <MenuItem value={el} key={i}>
                                    {el}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            );
        }

        textField = (which, label) => {
            const { calc } = this.store;

            return (
                <TextField
                    required
                    label={label}
                    fullWidth
                    size={"small"}
                    value={calc[which]}
                    onChange={(e) => {
                        calc[which] = e.target.value;
                    }}
                    error={Boolean (calc.errors[which])}
                    helperText={calc.errors[which] || ""}
                />
            );
        };

        render () {
            const { calc } = this.store;
            const { model, showCompoundFrequency, showContribution } = calc;

            return (
                <div className={"CompoundInterest"}>
                    <h1>Compound Interest</h1>

                    <CIRow>
                        <CIBlock header={"Overview"}>
                            <p>
                                This is a test harness over the compound interest calculator. The compound interest
                                calculator can be used to model a variety of vertical uses of this compounding model including:
                            </p>
                            <ul>
                                <li>CD calculator</li>
                                <li>high-yield savings account calculator</li>
                                <li>Bond calculator</li>
                            </ul>
                        </CIBlock>
                        <CIBlock header={"Models"}>
                            <p>
                                A variety of models are available that utilize the underlying compound interest calculator. Please
                                select which variant you'd like to use:
                            </p>
                            {this.select ("mode", "Mode", calc.MODE)}
                        </CIBlock>
                    </CIRow>
                    <CIRow>
                        <CIBlock header={"Configuration"}>
                            {this.textField ("initialDeposit", "Initial Deposit")}
                            <br/>
                            {showContribution && (
                                <>
                                    {this.textField ("contributionAmount", "Contribution Amount")}
                                    <br/>
                                    {this.select ("contributionFrequency", "Contribution Frequency", calc.CONTRIBUTION_FREQ)}
                                    <br/>
                                </>
                            )}
                            {this.textField ("yearsOfGrowth", "Years of Growth")}
                            <br/>
                            {this.textField ("rateOfReturn", "Rate of Return")}
                            <br/>
                            {showCompoundFrequency && (
                                this.select ("compoundFrequency", "Compound Frequency", calc.COMPOUND_FREQ)
                            )}
                        </CIBlock>
                        <CIBlock header={"Total Return"}>
                            <TextView model={model} />
                        </CIBlock>
                        <CIBlock header={"Highlights"}>
                            <Highlights model={model} />
                        </CIBlock>

                    </CIRow>
                    <CIRow>
                        <CIBlock header={"Histogram"}>
                            <HistogramView model={model} />
                        </CIBlock>
                        <CIBlock header={"Raw Configuration"} style={{ minHeight: 400 }}>
                            <Valid value={calc.isValid}/>
                            <pre>
                                {JSON.stringify (calc.store, null, 2)}
                            </pre>
                        </CIBlock>
                    </CIRow>
                    <CIRow>
                        <CIBlock header={"Modelled"}>
                            <DumpModel model={model} />
                        </CIBlock>
                        <CIBlock header={"Other"}>
                        </CIBlock>
                    </CIRow>

                </div>
            );
        }
    }
);

export default CompoundInterestApp;

// EOF