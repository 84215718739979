import React, { Component } from "react";
import "./MortgageHighlights.css";
import {toCurrency} from "../../util/Utils";
import {observer} from "mobx-react";

class HighlightBox extends Component {
    render () {
        const { quantity, label } = this.props;
        return (
            <div className={"Highlight"}>
                <div className={"Highlight-Quantity"}>
                    {quantity}
                </div>
                <div className={"Highlight-Label"}>
                    {label}
                </div>
            </div>
        );
    }
}

const MortgageHighlights = observer (
    class MortgageHighlights extends Component {
        render() {
            const { core } = this.props;

            return (
                <div className={"MortgageHighlights"}>
                    <HighlightBox label={"Payment"} quantity={toCurrency (core.payment)} />
                    <HighlightBox label={"Months"} quantity={core.months} />
                    <HighlightBox label={"Total Payments"} quantity={toCurrency (core.totalPayments)} />
                </div>
            );
        }
    }
);

export default MortgageHighlights;

export {
    HighlightBox
};

// EOF