import React, { Component } from "react";
import {Button, TextField} from "@mui/material";
import IncomeStore from "./IncomeStore";
import "./IncomeProfile.css";
import {observer} from "mobx-react";

const IncomeProfile = observer (
    class IncomeProfile extends Component {
        render() {
            return (
                <div className={"IncomeProfile"}>
                    <h2>Income Profile</h2>

                    <TextField
                        required
                        label={"Annual Income"}
                        fullWidth
                        value={IncomeStore.income || ""}
                        onChange={(e) => {
                            IncomeStore.income = e.target.value;
                        }}
                    />
                    <br/>
                    <div>
                        <Button size="small" variant={"outlined"} onClick={() => IncomeStore.clear ()}>
                            Clear
                        </Button>
                    </div>

                </div>
            );
        }
    }
);

export default IncomeProfile;

// EOF
