import {observable} from "mobx";

/**
 * A store used to manage the user's stated annual income.
 */

class IncomeStore {
    store = observable ({
        income: null
    });

    get income () {
        return this.store.income;
    }

    set income (newIncome) {
        this.store.income = newIncome;
    }

    clear () {
        this.store.income = null;
    }
}

export default new IncomeStore ();

// EOF