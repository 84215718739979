import React, { PureComponent } from "react";
import {observer} from "mobx-react";
import {PieChart, Pie, Legend, Tooltip, ResponsiveContainer, Cell, LabelList} from 'recharts';
import {fixedPoint, toCurrency} from "../../util/Utils";


const PieChart1 = observer (
    class PieChart1 extends PureComponent {
        render() {
            const { core } = this.props;
            const data = [
                { name: "Principal", value: fixedPoint (core.amount) },
                { name: "Interest", value: Number.parseFloat (core.interestPaid.toFixed (2)) }
            ];

            const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

            return (
                <div>
                    <PieChart width={500} height={500}>
                        <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={150} fill="#8884d8" label>
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <LabelList dataKey="label" formatter={(val) => toCurrency (val) }/>
                        <Legend verticalAlign="top" height={36}/>
                    </PieChart>
                </div>
            );
        }
    }
);

export default PieChart1;

// EOF