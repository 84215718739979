import React, { Component } from "react";
import {observer} from "mobx-react";
import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import {toCurrency} from "../../util/Utils";

/**
 * Charts documentation lives here... https://recharts.org/en-US/examples
 *
 * @type {{contextType?: React.Context<any> | undefined, new<P, S>(props: (Readonly<P> | P)): EquityView, new<P, S>(props: P, context: any): EquityView, prototype: EquityView}}
 */

const EquityView = observer (
    class EquityView extends Component {
        render() {
            const { core } = this.props;
            let data = core.data;

            const domain = [
                0,
                Math.ceil (data.amount / 10_000) * 10_000
            ];

            return (
                <div>
                    <LineChart
                        width={550}
                        height={400}
                        data={data}
                        // margin={{
                        //     top: 25,
                        //     right: 25,
                        //     left: 100,
                        //     bottom: 25,
                        // }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month"/>
                        <YAxis  type="number" domain={domain} allowDecimals={false} tickFormatter={(val) => `${(val/1000).toFixed(0)}k`}/>
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="principalPaid" stroke="#8884d8" dot={false}  />
                        <Line type="monotone" dataKey="principalRemaining" stroke="#82ca9d" dot={false} />
                    </LineChart>
                </div>
            );
        }
    }
);

export default EquityView;

// EOF