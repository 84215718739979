import React, { Component } from "react";
import Image from "./up-arrow.png";
class UpArrow extends Component {
    render() {
        return (
            <div style={{textAlign: "center"}}>
                <img src={Image} width={64} alt={"-"} />
            </div>
        );
    }
}

export default UpArrow;

// EOF