import React, { Component } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import _ from "lodash";
import {observer} from "mobx-react";

const PropertyTable = observer (
    class PropertyTable extends Component {
        render () {
            const { classes, value, header, ...props } = this.props;

            return (
                <Table size="small" className={"PropertyTable"} {...props}>
                    <TableHead>
                        <TableRow>
                            <TableCell component="th">
                                {header ? header [0] : "Property"}
                            </TableCell>
                            <TableCell component="th">
                                {header ? header[1] : "Value"}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.map (value, (val, key) => {
                            return (
                                <TableRow key={key}>
                                    <TableCell component="th" scope="row">
                                        {key}
                                    </TableCell>
                                    <TableCell>
                                        {val}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            );
        }
    }
);

export default PropertyTable;

// EOF