import React, { Component } from "react";
import "./css/Details.css";
import WinBox from "react-winbox";
import {objGet, wrap} from "../util/Utils";
import {Button, Checkbox, CircularProgress} from "@mui/material";
import PropertyTable from "../util/PropertyTable";
import _ from "lodash";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {observable} from "mobx";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const Details = wrap (
    class Details extends Component {
        store = observable ({
            showSettings: false
        });

        renderSettings (selected) {
            return (
                <div>
                    <table className={"Details-Table"}>
                        <thead>
                            <tr>
                                <th>Select properties to display...</th>
                                <th>
                                    <Button size={"small"} variant="outlined" onClick={() => {
                                        this.store.showSettings = false;
                                    }}>
                                        Close
                                    </Button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {_.map (QUOTE_DETAILS, (v, k) => {
                                return (
                                    <tr>
                                        <td colSpan={2}>
                                            <Checkbox checked={selected[k]} onClick={(e) => {
                                                selected[k] = ! Boolean (selected[k]);
                                            }} />
                                            &nbsp;
                                            {v}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            );
        }

        render () {
            const { data } = this.props;

            if (! data) {
                return (
                    <div style={{ margin: 20 }}>
                        <CircularProgress />
                    </div>
                );
            }

            const { conf } = this.props;
            const selected = conf.props.selected;

            if (this.store.showSettings) {
                return this.renderSettings (selected);
            }

            const props = { }
            _.each (QUOTE_DETAILS, (label, property) => {
                if (selected [property]) {
                    props [label] = data[property];
                }
            });

            const header = [
                <span>
                    Property
                    &nbsp;
                    <SettingsOutlinedIcon style={{ color: "lightgray" }} onClick={() => {
                        this.store.showSettings = true;
                    }} />
                </span>,
                "Value",
            ];

            return (
                <div className={"DetailsWrapper"}>
                    <PropertyTable value={props} header={header} />
                </div>
            );
        }
    }
);

const DetailsWindow = wrap (
    class DetailsWindow extends Component {
        static TYPE = "details";

        static newModule (index) {
            return {
                type: DetailsWindow.TYPE,
                size: { w: 780, h: 440 },
                coords: { x: 200, y: 200 },
                props: {
                    selected: {
                        "symbol": true,
                        "shortName": true,
                        "language": true,
                        "marketCap": true,
                        "forwardPE": true,
                        "bid": true,
                        "ask": true,
                        "bidSize": true,
                        "askSize": true,
                        "exchange": true
                    }
                },
                index
            };
        }

        render () {
            const { conf, data, ticker, controller } = this.props;
            const { coords, size } = conf;

            return (
                <WinBox
                    className={"Details-Window"}
                    title={"Details: " + ticker}
                    width={size.w}
                    height={size.h}
                    x={coords.x}
                    y={coords.y}
                    onClose={controller.close}
                    onMove={controller.setCoords}
                    onResize={controller.setSize}
                >
                    <Details data={data} conf={conf} />
                </WinBox>
            );
        }
    }
);

const QUOTE_DETAILS = {
    "symbol": "Symbol",
    "shortName": "Short Name",
    "language": "Language",
    "marketCap": "Market Cap",
    "forwardPE": "Forward PE",
    "bid": "Bid",
    "ask": "Ask",
    "bidSize": "Bid Size",
    "askSize": "Ask Size",
    "exchange": "Exchange",
    "exchangeTimezoneName": "Time Zone",
    "priceToBook": "Price to Book",
    "region": "Region",
    "quoteType": "Quote Type",
    "typeDisp": "Type Display",
    "quoteSourceName": "Quote Source",
    "triggerable": "Triggerable",
    "customPriceAlertConfidence": "Alert Confidence",
    "currency": "Currency",
    "marketState": "Market State",
    "longName": "Long Name",
    "messageBoardId": "Message Board",
    "exchangeTimezoneShortName": "Time Zone (short)",
    "gmtOffSetMilliseconds": "GMT Offset",
    "market": "Market",
    "esgPopulated": "ESG?",
    "regularMarketChangePercent": "Change Percent",
    "regularMarketPrice": "Market Price",
    "bookValue": "Book Value",
    "fiftyDayAverage": "50-day Ave.",
    "fiftyDayAverageChange": "50-day Avg. Delta",
    "fiftyDayAverageChangePercent": "50-day Avg. %",
    "twoHundredDayAverage": "200-day Avg.",
    "twoHundredDayAverageChange": "200-day Avg. Chg.",
    "twoHundredDayAverageChangePercent": "200-day Avg. Chg. %",
    "sourceInterval": "Source Interval",
    "exchangeDataDelayedBy": "Data Delay",
    "averageAnalystRating": "Avg. Analyst Rating",
    "tradeable": "Tradeable",
    "cryptoTradeable": "Is Crypto?",
    "firstTradeDateMilliseconds": "First trade date",
    "priceHint": "Price Hint",
    "regularMarketChange": "Regular Change",
    "regularMarketTime": "Regular Time",
    "regularMarketDayHigh": "Day High",
    "regularMarketDayRange": "Day Range",
    "regularMarketDayLow": "Day Low",
    "regularMarketVolume": "Volume",
    "regularMarketPreviousClose": "Previous Close",
    "fullExchangeName": "Exchange Name",
    "financialCurrency": "Currency",
    "regularMarketOpen": "Market Open",
    "averageDailyVolume3Month": "Volumn 3-month",
    "averageDailyVolume10Day": "Volume 10-day",
    "fiftyTwoWeekLowChange": "Low 52-week Chg.",
    "fiftyTwoWeekLowChangePercent": "Low 52-week Chg. %",
    "fiftyTwoWeekRange": "52-week Range",
    "fiftyTwoWeekHighChange": "52-week High Chg.",
    "fiftyTwoWeekHighChangePercent": "52-week High Chg. %",
    "fiftyTwoWeekLow": "52-week Low",
    "fiftyTwoWeekHigh": "52-week High",
    "dividendDate": "Dividend Date",
    "earningsTimestamp": "Earnings Time",
    "earningsTimestampStart": "Earnings Start",
    "earningsTimestampEnd": "Earnings End",
    "trailingAnnualDividendRate": "Trailing Dividend Rate",
    "trailingPE": "Trailing PE",
    "trailingAnnualDividendYield": "Trailing Dividend Yield",
    "epsTrailingTwelveMonths": "Trailing EPS",
    "epsForward": "Forward EPS",
    "epsCurrentYear": "EPS",
    "priceEpsCurrentYear": "Price/EPS",
    "sharesOutstanding": "Shares Outstanding",
    "displayName": "Display Name",
    "delta": "Delta"
};

export {
    Details,
    DetailsWindow
};

// EOF