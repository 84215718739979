import React, { Component } from "react";
import Mortgage from "./Mortgage";
import {Button, TextField} from "@mui/material";
import {observer} from "mobx-react";
import _ from "lodash";
import incomeStore from "./profile/IncomeStore";
import mortgageStore from "./profile/MortgageStore";

const DEFAULT = {
    RATE: 4.0,
    AMOUNT: 200_000,
    MONTHS: 360
};

const MortgageConfig = observer (
    class CalculatorConfig extends Component {
        componentDidMount() {
           this.reset ();
        }

        setAll (els) {
            const {core} = this.props;
            _.each(["rate", "amount", "months"], (el, i) => core[el] = els[i]);
        }

        clear () {
            this.setAll (["", "", ""]);
        }

        reset () {
            // Pick up what we can from the mortgage profile that they may have already set

            let { rate, amount, months } = mortgageStore;

            // Calculate and approximate mortgage based on their income if we don't otherwise
            // have one from the mortgage profile. We'll use their income times 6 rounded down to the
            // neared 10,000s.

            if (! amount) {
                const { income } = incomeStore;
                if (income) {
                    amount = incomeStore.income * 6 - income % 10_000;
                }
            }

            // Set the defaults for anything that we haven't already been able to gather

            if (! months) {
                months = DEFAULT.MONTHS;
            }
            if (! rate) {
                rate = DEFAULT.RATE;
            }
            if (! amount) {
                amount = DEFAULT.AMOUNT;
            }
            this.setAll ([ rate, amount, months ]);
        }

        render() {
            const { core } = this.props;
            const { errors } = core;

            const textField = (which, label) => {
                return (
                    <TextField
                        required
                        label={label}
                        fullWidth
                        value={core[which]}
                        onChange={(e) => {
                            core[which] = e.target.value;
                        }}
                        error={Boolean (errors[which])}
                        helperText={errors[which] || ""}
                    />
                );
            };

            console.log (core);
            return (
                <div className={"CalculatorCore"}>
                    <h2>Mortgage Config</h2>
                    {textField ("rate", "Interest Rate")}
                    <br/>
                    {textField ("amount", "Amount Financed")}
                    <br/>
                    {textField ("months", "Loan Duration")}
                    <div>
                        <Button size="small" variant={"outlined"} onClick={() => this.clear ()}>
                            Clear
                        </Button>
                        &nbsp;
                        <Button size="small" variant={"outlined"} onClick={() => this.reset ()}>
                            Reset
                        </Button>
                    </div>
                </div>
            );
        }
    }
);

export default MortgageConfig;

// EOF