import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "mobx-react";
import tickerStore from "./store/TickerStore";
import { snackbarStore } from "./store/SnackbarStore";
import {logStore} from "./store/LogStore";
import {sidebarStore } from "./store/SidebarStore";
import {infoDialogStore} from "./store/InfoDialogStore";
import {
    createBrowserRouter, createHashRouter,
    RouterProvider,
} from "react-router-dom";
import CalculatorApp from "./calc/CalculatorApp";
import {crumbStore} from "./store/CrumbStore";
import CompoundInterestApp from "./ci/CompoundInterestApp";


const router = createHashRouter([
    {
        path: "/",
        element: <App />
    },
    {
        path: "/calc",
        element: <CalculatorApp />
    },
    {
        path: "/ci",
        element: <CompoundInterestApp />
    }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Provider
            tickerStore={tickerStore}
            snackbarStore={snackbarStore}
            logStore={logStore}
            sidebarStore={sidebarStore}
            crumbStore={crumbStore}
            infoDialogStore={infoDialogStore}
      >
          {/*<App />*/}
          <RouterProvider router={router} />
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
