import React, { Component } from "react";
import {observer} from "mobx-react";
import "./RawOutputView.css";

const RawOutputView = observer (
    class RawOutputView extends Component {
        render() {
            const { core } = this.props;
            const { payment, totalPayments, interestPaid, data } = core;
            const output = {
                payment,
                totalPayments,
                interestPaid,
                data
            };
            return (
                <pre className={"RawOutputView"}>
                    {JSON.stringify (output, null, 2)}
                </pre>
            );
        }
    }
);

export default RawOutputView;

// EOF