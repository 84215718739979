import {observable} from "mobx";

/**
 * A store used to manage the details about the users mortgage.
 */
class MortgageStore {
    store = observable({
        rate: null,
        amount: null,
        months: null
    });

    get rate () {
        return this.store.rate;
    }

    set rate (newRate) {
        this.store.rate = newRate;
    }

    get amount () {
        return this.store.amount;
    }


    set amount (newAmount) {
        this.store.amount = newAmount;
    }

    get months () {
        return this.store.months;
    }

    set months (newMonths) {
        this.store.months = newMonths;
    }

    clear () {
        this.store.rate = null;
        this.store.amount = null;
        this.store.months = null;
    }
}

export default new MortgageStore ();

// EOF