import { logStore } from "./LogStore";
import _ from "lodash";
import {toJS} from "mobx";

class Yahoo {
    encodeSymbols (symbols) {
        if (_.isArray (symbols)) {
            let a = "";
            _.each (symbols, (symbol, i) => {
                if (a) {
                    a += ",";
                }
                a += symbol;
            });
            return a;
        } else {
            return symbols;
        }
    }

    /**
     * Formats a URL for retrieving a quote from the Yahoo quote endpoint. Note that this requires us to
     * proxy that call through the server that we loaded the page from due to CORS considerations.
     *
     * @param symbols
     * @returns {string}
     */
    getUrl (symbols) {
        // ACTUAL YAHOO
        // return "https://query1.finance.yahoo.com/v7/finance/quote?lang=en-US&region=US&corsDomain=finance.yahoo.com&symbols=" + this.encodeSymbols (symbols);
        // PROXIED LOCAL
        // return "/quote?lang=en-US&region=US&corsDomain=finance.yahoo.com&symbols=" + this.encodeSymbols (symbols);
        // PROXIED EXPLICIT
        return "https://bloomberg.etherfirma.com/quote?lang=en-US&region=US&corsDomain=finance.yahoo.com&symbols=" + this.encodeSymbols (symbols);
    }

    async getQuotes (symbols) {
        try {
            const url = this.getUrl (symbols);
            logStore.updateLog ("Loading data for " + symbols + "...");
            const res = await fetch (url);
            const json = await res.json ();
            logStore.updateLog ("Loaded data for " + symbols);
            return json;
        }
        catch (e) {
            console.log (e);
            return e;
        }
    }
}

export default new Yahoo ();

// EOF