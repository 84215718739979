import React, { Component } from "react";
import {action, observable} from "mobx";
import {Drawer} from "@mui/material";
import { wrap } from "../util/Utils";
import {TickerPicker} from "../modules/TickerPicker";

const store = observable ({
    show: true,
    showSidebar: action (() => store.show = true),
    hideSidebar: action (() => store.show = false)
});


const Sidebar = wrap (
    class Sidebar extends Component {
        render () {
            return (
                <Drawer
                    className={"Sidebar"}
                    open={store.show}
                    anchor={"right"}
                    onClose={() => store.hideSidebar ()}
                    PaperProps={{ style: { width: 350 } }}
                >
                    <TickerPicker />
                </Drawer>
            );
        }
    }
);

export default Sidebar;

export {
    store as sidebarStore
};

// EOF