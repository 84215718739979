import React, { Component } from "react";
import WinBox from "react-winbox";
import { wrap } from "../util/Utils";
import "./css/TestStore.css";

const TestPanel = wrap (
    class TestPanel extends Component {
        static TYPE = "testStore";
        render() {
            return (
                <div style={{ padding: 8 }}>
                    <h4>Tests</h4>
                    <ul>
                        <li onClick={() => {
                            const { snackbarStore } = this.props;
                            snackbarStore.show ("Test!");
                        }}>
                            Snackbar
                        </li>
                        <li onClick={() => {
                            const { infoDialogStore } = this.props;
                            const props = {
                                title: "Title",
                                body: (
                                    <div>
                                        <h4>Title</h4>
                                        <p>
                                            Lorem upsum...
                                        </p>
                                    </div>
                                ),
                                dialogProps: { maxWidth: "sm", fullWidth: true }
                            };
                            infoDialogStore.showDialog (props);
                        }}>
                            InfoDialog
                        </li>
                        <li onClick={() => {
                            const { infoDialogStore } = this.props;
                            infoDialogStore.showJson ({
                                foo: "bar",
                                baz: [
                                    { boo: { "hi": "there" } }
                                ]
                            });
                        }}>
                            JSON
                        </li>
                    </ul>
                </div>
            );
        }
    }
);

const TestWindow = wrap (
    class TestWindow extends Component {
        static TYPE = "$testStore";

        static newModule () {
            return {
                type: TestWindow.TYPE,
                size: { w: 200, h: 400 },
                coords: { x: 400, y: 400 }
            };
        }
        render () {
            const { conf, tickerStore } = this.props;
            const { coords, size } = conf;
            console.log ("TestStore", conf);

            return (
                <WinBox
                    className={"Test-Window"}
                    // width={this.state.boxWidth ?? 500}
                    width={size.w}
                    height={size.h}
                    x={coords.x}
                    y={coords.y}
                    title={"Tests"}
                    // noClose={this.state.inEditing}
                    onMove={(x, y) => {
                        conf.coords = { x, y };
                    }}
                    onResize={(w, h) => {
                        conf.size = { w, h };
                    }}
                >
                    <TestPanel />
                </WinBox>
            );
        }
    }
);

export default TestWindow;


// EOF