import React, { Component } from "react"; 
import _ from "lodash";
import { random } from "./Utils";

const IMAGES = [
    "https://s.yimg.com/uu/api/res/1.2/eZW1UzzVDzkhNNvcw.RJ9w--~B/Zmk9c3RyaW07aD0xNDQ7cT04MDt3PTE0NDthcHBpZD15dGFjaHlvbg--/https://media.zenfs.com/en/techcrunch_350/56963060b2f66d697071c7f694e75d55.cf.webp",
    "https://s.yimg.com/uu/api/res/1.2/dPOnb.QGTuL9F.wtksKhyw--~B/Zmk9c3RyaW07aD0xNDQ7cT04MDt3PTE0NDthcHBpZD15dGFjaHlvbg--/https://s.yimg.com/os/creatr-uploaded-images/2023-03/5ebebd40-d179-11ed-adff-6fa5bd12e59b.cf.webp",
    "https://s.yimg.com/uu/api/res/1.2/_vp4fZfxmj6aIs3Xu4X55g--~B/Zmk9c3RyaW07aD0xMzA7dz0xMzA7YXBwaWQ9eXRhY2h5b24-/https://s.yimg.com/os/creatr-uploaded-images/2023-04/96ddb340-d203-11ed-babe-88dc464309ca.cf.webp",
    "https://s.yimg.com/uu/api/res/1.2/nvPRATAjLBn4PaCoyenUyw--~B/Zmk9c3RyaW07aD0xMzA7dz0xMzA7YXBwaWQ9eXRhY2h5b24-/https://s.yimg.com/os/creatr-uploaded-images/2023-04/f6452630-d210-11ed-b9f7-913da55c28b0.cf.webp",
    "https://s.yimg.com/uu/api/res/1.2/6mcur3ZNHmxEjiz.wSm84w--~B/Zmk9c3RyaW07aD0xMzA7dz0xMzA7YXBwaWQ9eXRhY2h5b24-/https://s.yimg.com/os/creatr-uploaded-images/2023-03/5c696320-d19b-11ed-87fd-03e0cf35f9f7.cf.webp",
    "https://s.yimg.com/uu/api/res/1.2/wIN3iuhyGj2CTn4wd4H7gQ--~B/Zmk9c3RyaW07aD0xMzA7dz0xMzA7YXBwaWQ9eXRhY2h5b24-/https://s.yimg.com/os/creatr-uploaded-images/2023-03/c2d742c0-d0a2-11ed-b1bf-c39b6d0c05ed.cf.webp"
];

const URLS = [
    "https://finance.yahoo.com/news/tesla-reports-record-q1-deliveries-as-price-cuts-boost-demand-183219769.html",
    "https://finance.yahoo.com/news/how-warren-buffett-could-steal-the-show-in-the-second-quarter-morning-brief-100034766.html",
    "https://finance.yahoo.com/news/wwe-and-ufc-owner-endeavor-agree-to-massive-merger-110951505.html",
    "https://finance.yahoo.com/news/oil-rockets-8-opec-jolts-225758341.html"
];

const SHORT = [
    "Lorem ipsum dolor sit amet",
    "Consectetur adipiscing elit",
    "Quisque efficitur sit amet justo",
    "Donec eu malesuada massa",
    "Aenean aliquam venenatis sodales",
    "Tiam accumsan efficitur erat vel venenatis",
    "Suspendisse ut dapibus sem, tempus ultrices est",
    "Aenean finibus, nunc pulvinar",
    "Maecenas cursus et felis ac hendrerit",
    "Nullam diam ipsum",
    "Iaculis eu nisi eget",
    "Commodo ullamcorper nibh",
    "Aenean feugiat pellentesque lacus a placerat",
    "Sed vulputate, velit vitae",
    "Sagittis faucibus",
    "Erat velit euismod ante",
    "Rutrum arcu nibh pulvinar nisl",
    "Nam sed justo at metus sodales pellentesque",
    "Ut id cursus ipsum",
    "Nunc accumsan tincidunt erat ut scelerisque",
    "Sed sed risus et quam luctus",
    "Nunc viverra lacus non dolor mollis",
    "At facilisis tortor dapibus",
    "Curabitur a finibus lacus",
    "Vestibulum finibus tincidunt pretium",
    "Donec congue lacus sit amet pharetra dictum",
    "Duis eu orci ante",
    "Vestibulum ante ipsum primis",
    "In faucibus orci",
    "Et ultrices posuere cubilia curae",
];

const MEDIUM = [
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque efficitur sit amet justo quis blandit.",
    "Donec eu malesuada massa. Aenean aliquam venenatis sodales. Etiam accumsan efficitur erat vel venenatis.",
    "Suspendisse ut dapibus sem, tempus ultrices est. Aenean finibus, nunc pulvinar facilisis ultrices, augue quam sodales quam.",
    "Ac elementum arcu erat malesuada leo. Maecenas cursus et felis ac hendrerit. Nullam diam ipsum, iaculis eu nisi eget, commodo ullamcorper nibh.",
    "Aenean feugiat pellentesque lacus a placerat. Sed vulputate, velit vitae sagittis faucibus, erat velit euismod ante, a rutrum arcu nibh pulvinar nisl.",
    "Nam sed justo at metus sodales pellentesque. Ut id cursus ipsum. Nunc accumsan tincidunt erat ut scelerisque.",
    "Sed sed risus et quam luctus egestas quis vitae justo. Nunc viverra lacus non dolor mollis, at facilisis tortor dapibus.",
    "Curabitur a finibus lacus. Vestibulum finibus tincidunt pretium. Donec congue lacus sit amet pharetra dictum. Duis eu orci ante.",
    "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae.",
    "Aenean at sodales metus. Nulla laoreet leo ut ligula elementum, sit amet sollicitudin nunc cursus. Mauris elementum, tellus a mattis consequat, risus odio interdum turpis.",
    "Vel accumsan dui risus eget nisi. Suspendisse ac sem at nulla imperdiet interdum quis eget urna. Nunc cursus, sem in aliquam laoreet, arcu purus pulvinar nisi.",
    "Sed dignissim nisl massa et dolor. Cras interdum molestie nibh non vulputate. Phasellus sit amet tortor nisl. Praesent sagittis leo commodo lacus congue tristique.",
    "Sed dictum quam nec lacus eleifend, vel ornare nisi scelerisque. Vivamus sollicitudin neque orci, vel tempus dolor ornare id. Donec luctus iaculis leo in ultrices.",
    "In lobortis aliquam consectetur. Suspendisse efficitur euismod lacus, sed finibus sem vehicula at. Pellentesque finibus augue urna, non luctus felis semper accumsan.",
    "Maecenas quis ex a lorem venenatis laoreet id non mi. Donec vel magna mi. In id nibh lorem. Proin posuere feugiat vulputate. Curabitur neque risus.",
    "Sagittis quis quam quis, dapibus interdum nulla. Nam id lorem nibh. Donec bibendum quam a massa pretium finibus. Aenean sapien quam, luctus quis tincidunt in, lobortis vitae lacus.",
    "Nullam et odio quis orci pretium vehicula. Sed sodales erat id lacus egestas pretium.",
    "Nam dui quam, volutpat ac venenatis quis, condimentum eget lectus. Cras vitae vulputate tellus, eget gravida magna.",
    "Aliquam ornare magna at placerat vestibulum. Mauris mollis rhoncus mollis. Vivamus mattis turpis a nisl porta convallis. In pulvinar aliquet euismod.",
    "Sed venenatis purus lacus, at suscipit ante dignissim in. Vestibulum sagittis lectus in urna rhoncus volutpat. Proin nec velit ante.",
    "Maecenas semper maximus lectus, ut tincidunt mi sodales eu. Cras id mauris tempus, tristique orci a, tristique tellus."
];

const PUBLISHERS = [
    "Yahoo Finance!",
    "Bloomberg",
    "Sports Illustrated",
    "MAD Magazine",
    "Cosmopolitan",
    "Popular Science",
    "CBS Marketwatch",
    "MSNBC",
    "Farmer's Almanac"
];

class Lipsum {
    pick (n, arr) {
        const els = [];
        while (els.length < n) {
            const pick = random (arr.length);
            if (_.indexOf (els, pick) === -1) {
                els.push (pick);
            }
        }
        return _.map (els, (el => arr[el]));
    }

    images (n) {
        return this.pick(n, IMAGES);
    }

    urls (n) {
        return this.pick(n, URLS);
    }

    publishers (n) {
        return this.pick (n, PUBLISHERS);
    }

    short (n) {
        return this.pick (n, SHORT);
    }
    
    medium (n) {
        return this.pick (n, MEDIUM);
    }
}

export default new Lipsum (); 

// EOF