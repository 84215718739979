import React, { Component } from "react";
import WinBox from "react-winbox";
import {Button} from "@mui/material";
import { wrap } from "../util/Utils";
import "./css/BigButtonPanel.css";

/**
 *
 * @type {*}
 */
const BigButtonPanel = wrap (
    class BigButtoPanel extends Component {
        render() {
            return (
                <div className={"BigButtonPanel"}>
                    <Button size={"large"} variant={"outlined"}>
                        Click me!
                    </Button>
                </div>
            );
        }
    }
);

class BigButtonWindow extends Component {
    render() {
        const { conf } = this.props;
        const { id, size, coords } = conf;

        return (
            <WinBox
                title={"WINDOW " + id}
                width={size.w}
                height={size.h}
                x={coords.x}
                y={coords.y}
                onClose={() => {
                    console.log (id + " CLOSE");
                }}
                onMove={(x, y) => {
                    console.log (id + " (" + x + ", " + y + ")");
                }}
                onResize={(w, h) => {
                    console.log (id + " [" + w + " x " + h + "]");
                }}
            >
                <BigButtonPanel conf={conf} />
            </WinBox>
        );
    }
}

export {
    BigButtonPanel,
    BigButtonWindow
};

// EOF