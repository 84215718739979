import React, { Component } from "react";
import './App.css';
import {TickerPicker, TickerPickerWindow} from "./modules/TickerPicker.js"
import ActiveTickers, {ActiveTickerWindow} from "./modules/ActiveTickers";
import SnackbarPopop from "./store/SnackbarStore";
import {LogStoreWindow} from "./store/LogStore";
import {DataBrowserWindow} from "./store/DataBrowser";
import {SummaryWindow} from "./modules/Summary";
import { wrap } from "./util/Utils";
import _ from "lodash";
import {ChartWindow} from "./modules/Chart";
import {DetailsWindow} from "./modules/Details";
import Header from "./modules/Header";
import Sidebar from "./store/SidebarStore";
import {Button} from "@mui/material";
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import TestWindow from "./store/TestStore";
import InfoDialog from "./store/InfoDialogStore";
import {action, toJS} from "mobx";
import {NewsWindow} from "./modules/News";
import {BigButtonWindow} from "./modules/BigButton";

const App = wrap (
    class App extends Component {
        render() {
            const {tickerStore} = this.props;
            const tickers = tickerStore.tickers;

            return (
                <div>
                    <Header/>
                    <div className={"AppWrapper"}>
                        <div className={"App"}>
                            {_.map(tickerStore.modules, (el, i) => {
                                const {index} = el;
                                const ticker = index !== undefined ? tickers [index] : undefined;
                                const data = index !== undefined ? tickerStore.data [ticker] : undefined;
                                const controller = {
                                    setCoords: action ((x, y) => {
                                        el.coords = { x, y };
                                    }),
                                    setSize: action ((w, h) => {
                                        el.size = { w, h };
                                    }),
                                    close: (flag) => {
                                        if (! flag) {
                                            tickerStore.removeModule (el.id);
                                            return true;
                                        }
                                    }
                                };
                                const props = {
                                    key: i,
                                    conf: el,
                                    controller,
                                    ticker,
                                    data
                                };
                                const Module = this.toModule(el.type);
                                return <Module {...props} />
                            })}
                            <SnackbarPopop/>
                            <InfoDialog/>
                        </div>
                        <Sidebar/>
                    </div>
                </div>
            );
        }

        /**
         *
         * @param type
         * @returns {(* & IWrappedComponent<never>)|(* & IWrappedComponent<never>)|null}
         */
        toModule(type) {
            switch (type) {
                // Ticker-independent
                case LogStoreWindow.TYPE:
                    return LogStoreWindow;
                case ActiveTickerWindow.TYPE:
                    return ActiveTickerWindow;
                case DataBrowserWindow.TYPE:
                    return DataBrowserWindow;
                case TestWindow.TYPE:
                    return TestWindow;

                // Ticker-specific
                case SummaryWindow.TYPE:
                    return SummaryWindow;
                case NewsWindow.TYPE:
                    return NewsWindow;
                case DetailsWindow.TYPE:
                    return DetailsWindow;
                case ChartWindow.TYPE:
                    return ChartWindow;

                default: {
                    console.error("Unrecognized type: " + type);
                    return null;
                }
            }
            // NOT REACHED
        }
    }
);

export default App;
