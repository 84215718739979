import React, { Component } from "react";
import {inject, observer} from "mobx-react";
import WinBox from "react-winbox";
import _ from "lodash";
import { wrap } from "../util/Utils";
import "./css/ActiveTickers.css";

const stores = [ "tickerStore" ];

const ActiveTickers = inject (...stores)(observer (
    class ActiveTickers extends Component {

        render() {
            const { tickerStore } = this.props;
            return (
                <div className={"ActiveTickers"}>
                    <h4>Active Tickers</h4>
                    <ol>
                        {_.map (tickerStore.tickers, (ticker, i) => {
                            return (
                                <li key={i}>
                                    <tt>{ticker || <b>EMPTY</b>} </tt>
                                </li>
                            );
                        })}
                    </ol>
                </div>
            )
        };
    }
));

const ActiveTickerWindow = wrap (
    class ActiveTickerWindow extends Component {
        static TYPE = "$activeTickers";

        static newModule () {
            return {
                type: ActiveTickerWindow.TYPE,
                size: { w: 250, h: 300 },
                coords: { x: 100, y: 250 }
            };
        }

        render() {
            const { conf, tickerStore } = this.props;
            const { coords, size } = conf;
            console.log ("ActiveTickers", conf);

            return (
                <WinBox
                    // width={this.state.boxWidth ?? 500}
                    width={size.w}
                    height={size.h}
                    title={"Active Ticker"}
                    x={coords.x}
                    y={coords.y}
                    onClose={() => {
                        controller.close ();
                    }}
                    onMove={(x, y) => {
                        controller.setCoords (x, y);
                    }}
                    onResize={(w, h) => {
                        controller.setSize (w, h);
                    }}
                >
                    <ActiveTickers />
                </WinBox>

            );
        }
    }
);

export default ActiveTickers;

export {
    ActiveTickerWindow
};

// EOF