import React, { Component } from "react";
import {Button, TextField} from "@mui/material";
import MortgageStore from "./MortgageStore";
import "./MortgageProfile.css";
import {observer} from "mobx-react";

const MortgageProfile = observer (
    class MortgageProfile extends Component {

        render() {
            return (
                <div className={"MortgageProfile"}>
                    <h2>Mortgage Profile</h2>
                    <TextField
                        required
                        label={"Interest Rate"}
                        fullWidth
                        value={MortgageStore.rate || ""}
                        onChange={(e) => {
                            MortgageStore.rate = e.target.value;
                        }}
                    />
                    <br/>
                    <TextField
                        required
                        label={"Amount Financed"}
                        fullWidth
                        value={MortgageStore.amount || ""}
                        onChange={(e) => {
                            MortgageStore.amount = e.target.value;
                        }}
                    />
                    <br/>
                    <TextField
                        required
                        label={"Duration (months)"}
                        fullWidth
                        value={MortgageStore.months || ""}
                        onChange={(e) => {
                            MortgageStore.months = e.target.value;
                        }}
                    />
                    <br/>
                    <div>
                        <Button size="small" variant={"outlined"} onClick={() => MortgageStore.clear ()}>
                            Clear
                        </Button>
                    </div>
                </div>
            );
        }
    }
);

export default MortgageProfile;

// EOF
