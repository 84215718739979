import React, { Component } from "react";
import "./css/Chart.css";
import WinBox from "react-winbox";
import { wrap } from "../util/Utils";
import AAPL from "./aapl-chart.png";
import TSLA from "./tsla-chart.png";
import META from "./meta-chart.png";
import KO from "./ko-chart.png";
import T from "./t-chart.png"
import { action } from "mobx";

const Chart = wrap (
    class Chart extends Component {
        render () {
            const { ticker } = this.props;

            return (
                <div className={"ChartWrapper"}>
                    <img style={{ width: "100%" }} src={this.getImageUrl (ticker)} alt={ticker} />
                </div>
            );
        }

        static IMAGES = {
            AAPL: AAPL,
            META: META,
            TSLA: TSLA,
            KO: KO,
            T: T
        };

        getImageUrl (ticker) {
            return Chart.IMAGES[ticker] || AAPL;
        }
    }
);

const ChartWindow = wrap (
    class ChartWindow extends Component {
        static TYPE = "chart";

        static newModule (index) {
            return {
                type: ChartWindow.TYPE,
                size: { w: 780, h: 440 },
                coords: { x: 200, y: 200 },
                props: { },
                index
            };
        }

        render () {
            const { conf, data, ticker, controller } = this.props;
            const { coords, size } = conf;

            return (
                <WinBox
                    className={"Chart-Window"}
                    title={"Chart: " + ticker}
                    width={size.w}
                    height={size.h}
                    x={coords.x}
                    y={coords.y}
                    onClose={controller.close}
                    onMove={controller.setCoords}
                    onResize={controller.setSize}
                >
                    <Chart ticker={ticker} />
                </WinBox>
            );
        }
    }
);

export {
    Chart,
    ChartWindow
};

// EOF