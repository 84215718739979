import React, { Component } from "react";
import {inject, observer} from "mobx-react";
import {Typography} from "@mui/material";
import _ from "lodash";

const standardInjects = [ "tickerStore", "snackbarStore", "logStore", "infoDialogStore" ];

const wrap = (thing, extra) => {
    if (extra) {
        return inject(...[...standardInjects, ...extra])(observer(thing));
    }  else {
        return inject(...standardInjects)(observer(thing));
    }
}

function invokeMaybe (func) {
    return function () {
        if (func) {
            func.apply (null, arguments);
        } else {
            return;
        }
    }
}

const guid = () => {
    let s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    };
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
};
const TabPanel = observer (
    class TabPanel extends Component  {
        render () {
            const {children, value, index, ...other} = this.props;

            return (
                <div
                    role="tabpanel"
                    hidden={value !== index}
                    id={`simple-tabpanel-${index}`}
                    aria-labelledby={`simple-tab-${index}`}
                    {...other}
                >
                    {value === index && (
                        <div>{children}</div>
                    )}
                </div>
            );
        }
    }
);

const objGet = (obj, path) => {
    if (! _.isArray (path)) {
        path = path.split(".");
    }
    for (let i = 0; i < path.length; i ++) {
        if (! obj) {
            return undefined;
        }
        obj = obj[path[i]];
    }
    return obj;
};

const random = (max) => {
    return Math.floor (Math.random () * max);
};

const IS_FLOAT = /^[+-]?(?:\d*\.)?\d*$/;

const isFloat = (value) => {
    return IS_FLOAT.test (value);
};

const IS_INT = /^\d+$/;

const isInt = (value) => {
    console.log ("isInt", value, IS_INT.test (value));
    return IS_INT.test(value);
}
const fixedPoint = (val, dec = 2) => Number.parseFloat (Number.parseFloat(val).toFixed(dec));

const CURRENCY_FORMATTER = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

const toCurrency = (val) => {
    return CURRENCY_FORMATTER.format (val);
};

const generateSequence = (cnt, max) => {
    const els = [];
    for (let i = 0; i < cnt; i ++) {
        if (max) {
            if ( i >= max) {
                break;
            }
        }
        els.push (i);
    }
    return els;
}

export {
    generateSequence,
    isFloat,
    toCurrency,
    fixedPoint,
    isInt,
    wrap,
    random,
    objGet,
    TabPanel,
    invokeMaybe,
    guid
};

// EOF