import React, { Component } from "react";
import {observer} from "mobx-react";
import {toCurrency} from "../../util/Utils";
import {HighlightBox} from "./MortgageHighlights";

/**
 *
 * @type {{contextType?: React.Context<any> | undefined, new<P, S>(props: (Readonly<P> | P)): Component, new<P, S>(props: P, context: any): Component, prototype: Component}}
 */

const Components = observer (
    class Component extends Component {
        render() {
            const { core } = this.props;
            return (
                <div className={"MortgageHighlights"}>
                    <table>
                        <tbody>
                        <tr>
                            <td>
                                <div className={"Highlight-Label"}>
                                    Principal
                                </div>
                            </td>
                            <td>
                                <div className={"Highlight-Quantity"}>
                                    {toCurrency (core.amount)}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className={"Highlight-Label"}>
                                    Interest
                                </div>
                            </td>
                            <td>
                                <div className={"Highlight-Quantity"} style={{ textDecoration: "underline" }}>
                                   + {toCurrency (core.interestPaid)}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className={"Highlight-Label"}>
                                    Lifetime Cost of Mortgage
                                </div>
                            </td>
                            <td>
                                <div className={"Highlight-Quantity"}>
                                    {toCurrency (core.totalPayments)}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            );
        }
    }
);

export default Components;

// EOF